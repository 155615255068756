
import { defineComponent, defineProps, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { themeLightLogo } from "@/core/helpers/config";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  computed: {
    newPasswordFieldType() {
      if (this.newPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
    passwordConfirmationFieldType() {
      if (this.passwordConfirmationVisible) {
        return "text";
      } else {
        return "password";
      }
    }
  },
  methods: {
    toggleNewPasswordVisible() {
      this.newPasswordVisible = !this.newPasswordVisible;
    },
    togglePasswordConfirmationVisible() {
      this.passwordConfirmationVisible = !this.passwordConfirmationVisible;
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

// Extract query parameters
    const email = route.query.email
    const token = route.query.token
    const newPasswordVisible = ref(false);
    const passwordConfirmationVisible = ref(false);
    const submitButton = ref<HTMLElement | null>(null);

    if (!email || !token) {
      //router.push({ name: "sign-in" });
    }
  
    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .required("Este campo es requerido")
        .label("Username"),
      password: Yup.string()
        .min(8, "Debe contener al menos 8 caracteres.")
        .required("Este campo es requerido")
        .label("Nueva contraseña"),
    });

    const usernameField = ref(email);

    //Form submit function
    const onSubmitResetPassword = (values) => {

      store
        .dispatch(Actions.RESET_PASSWORD, {
          email: values.email,
          token: token,
          password: values.password,
          password_confirmation: values.password_confirmation
        })
        .then((response) => {
          Swal.fire({
              text: response.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Okay",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          router.push({ name: "sign-in" });
        })
        .catch((data) => {
          Swal.fire({
            text: store.getters.getErrors[0]
              ? store.getters.getErrors[0]
              : store.getters.getErrors.password,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Volver",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      usernameField,
      newPasswordVisible,
      passwordConfirmationVisible,
      onSubmitResetPassword,
      login,
      themeLightLogo,
      submitButton,
    };
  },
});
